import React, { useState } from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { logout, isLoggedIn, isMod } from '../utils/auth'
import { Auth } from 'aws-amplify'
import Transition from './shared/Transition'
import logo from '../components/icons/funFactLogo/horizontal-white.png'

const Header = ({ siteTitle }) => {
    const [mobileMenuHidden, setMobileMenuHidden] = useState(true)
    const [profileMenuHidden, setProfileMenuHidden] = useState(true)

    const toggleMenu = () => {
        setMobileMenuHidden(!mobileMenuHidden)
    }

    const toggleProfileMenu = () => {
        setProfileMenuHidden(!profileMenuHidden)
    }

    const signOut = () => {
        Auth.signOut()
            .then(logout(() => navigate('/app/login')))
            .catch(err => console.log('eror:', err))
    }

    return (
        <nav className="bg-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="flex">
                        <div className="-ml-2 mr-2 flex items-center md:hidden">
                            {/* <!-- Mobile menu button --> */}
                            <button
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
                                aria-label="Main menu"
                                aria-expanded="false"
                                onClick={toggleMenu}
                            >
                                {mobileMenuHidden && (
                                    <svg
                                        className="block h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                )}
                                {!mobileMenuHidden && (
                                    <svg
                                        className="block h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                        <div className="flex-shrink-0 flex items-center">
                            <Link
                                to={'/'}
                            >
                                <img
                                    className="block h-8 w-auto"
                                    src={logo}
                                    alt="Fun Fact Checker"
                                />
                            </Link>
                        </div>
                        <div className="hidden md:ml-6 md:flex md:items-center">
                            {/* <Link
                                to={'/app/explore'}
                                className="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                            >
                                Explore
                            </Link> */}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <span className="rounded-md shadow-sm">
                                <Link
                                    to="/app/facts/create"
                                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ffpurple-500 hover:bg-ffpurple-400 focus:outline-none focus:ring-ffpurple focus:border-ffpurple-600 active:bg-ffpurple-600 transition duration-150 ease-in-out"
                                >
                                    <svg
                                        className="-ml-1 mr-2 h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span>New Fun Fact</span>
                                </Link>
                            </span>
                        </div>
                        {!isLoggedIn() && (
                            <div className="flex-shrink-0 ml-3">
                                <span className="rounded-md shadow-sm">
                                    <Link
                                        to="/app/login"
                                        className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-ffpurple-500 bg-white hover:bg-gray-200 focus:outline-none focus:ring-ffpurple focus:border-ffpurple-600 active:bg-ffpurple-600 transition duration-150 ease-in-out"
                                    >
                                        <span>Log In</span>
                                    </Link>
                                </span>
                            </div>
                        )}

                        <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                            {/* NOTIFICATION BELL */}
                            {/* <button
                                className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-300 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition duration-150 ease-in-out"
                                aria-label="Notifications"
                            >
                                <svg
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                    />
                                </svg>
                            </button> */}

                            {/* <!-- Profile dropdown --> */}
                            {isLoggedIn() && (
                                <div className="relative">
                                    <div>
                                        <button
                                            className="flex text-sm px-4 py-2 border-2 border-transparent rounded-md shadow-sm focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out text-ffpurple-500 bg-white"
                                            id="user-menu"
                                            aria-label="User menu"
                                            aria-haspopup="true"
                                            onClick={toggleProfileMenu}
                                        >
                                            {/* <img
                                                className="h-8 w-8 rounded-full"
                                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                            /> */}
                                            <span>Me</span>
                                        </button>
                                        {/* <span className="rounded-md shadow-sm">
                                            <Link
                                                to="/app/login"
                                                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-ffpurple-500 bg-white hover:bg-gray-200 focus:outline-none focus:ring-ffpurple focus:border-ffpurple-600 active:bg-ffpurple-600 transition duration-150 ease-in-out"
                                            >
                                                <span>Log In</span>
                                            </Link>
                                        </span> */}
                                    </div>
                                    <Transition
                                        enter="transition ease-out duration-200"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                        show={!profileMenuHidden}
                                    >
                                        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                                            <div
                                                className="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="user-menu"
                                            >
                                                <Link
                                                    to={'/app/profile'}
                                                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                                    role="menuitem"
                                                >
                                                    Your Profile
                                                </Link>
                                                {isMod() && (
                                                    <Link
                                                        to={'/app/moderator'}
                                                        className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                                        role="menuitem"
                                                    >
                                                        Moderators Dashboard
                                                    </Link>
                                                )}

                                                <button
                                                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                                    role="menuitem"
                                                    onClick={signOut}
                                                >
                                                    Sign out
                                                </button>
                                            </div>
                                        </div>
                                    </Transition>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* 
            <!--
                Mobile menu, toggle classes based on menu state.

                Menu open: "block", Menu closed: "hidden"
            --> */}
            <div
                className={`${mobileMenuHidden ? 'hidden md:hidden' : 'block md:block'
                    }`}
            >
                <div className="px-2 pt-2 pb-3 sm:px-3">
                    {/* <Link
                        to={'/app/explore'}
                        className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                    >
                        Explore
                    </Link> */}
                    {/* GET RANDOM FACT */}
                </div>
                {isLoggedIn() && (
                    <div className="pt-4 pb-3 border-t border-gray-700">
                        {/* <div className="flex items-center px-5 sm:px-6">
                            <div className="flex-shrink-0">
                                <img
                                    className="h-10 w-10 rounded-full"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    alt=""
                                />
                            </div>
                        </div> */}
                        <div className="mt-3 px-2 sm:px-3">
                            <Link
                                to={'/app/profile'}
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                            >
                                Your Profile
                            </Link>
                            {isMod() && (
                                <Link
                                    to={'/app/moderator'}
                                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                                >
                                    Moderators Dashboard
                                </Link>
                            )}
                            <button
                                onClick={signOut}
                                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
                            >
                                Sign out
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    )
}

export default Header
